import React from "react";
import Logo from '../logo.png';
import iconcontact from '../icon-contact.svg'

export const Navigation = () => {
 
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <img src={Logo}></img>

        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">


          
            <li>
              <button href="#team" className="enroll">
                Enroll Now
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
